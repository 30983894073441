export const DateIndex = 0;
export const VisitorsIndex = 1;
export const CopiersIndex = 2;
export const IncomeIndex = 3;
export const PayoutIndex = 4;
export const RefundsIndex = 5;
export const PayoutAvailableIndex = 6;
export const TableDataLength = 7;
const Dict = {
    [`${DateIndex}`]: 'date',
    [`${VisitorsIndex}`]: 'visitors',
    [`${CopiersIndex}`]: 'copiers',
    [`${IncomeIndex}`]: 'income',
    [`${PayoutIndex}`]: 'payout',
    [`${RefundsIndex}`]: 'refunds',
    [`${PayoutAvailableIndex}`]: 'available_payout',
};
// NOTE:
// in order to display table data as horizontal table
// 
export const mapTraderActivityForTable = (data) => {
    const initArr = new Array(TableDataLength).fill({});
    if (!data.length)
        return [];
    return initArr.map((item, index) => ({
        'data-row': Dict[index],
        'data-1': data[0][Dict[index]],
        'data-2': data[1][Dict[index]],
        'data-3': data[2][Dict[index]],
        'data-4': data[3][Dict[index]],
        'data-5': data[4][Dict[index]],
        'data-6': data[5][Dict[index]],
        'data-7': data[6][Dict[index]],
        'data-8': data[7][Dict[index]],
        'data-9': data[8][Dict[index]],
        'data-10': data[9][Dict[index]],
        'data-11': data[10][Dict[index]],
        'data-12': data[11][Dict[index]],
    }));
};
