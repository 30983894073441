import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { BodySmall, TitleSecondary } from '@src/components/styled/Typography';
import SegmentButtons from '@shared/ui/buttons/SegmentButtons';
import Table, { tableRendererWrapper } from '@shared/ui/display/Table';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import { mapTraderActivityForTable } from './utils';
import ActivityCell from '../../table-components/ActivityCell';
import ActivityBodyRow from '../../table-components/ActivityBodyRow';
import ActivityHeaderCell from '../../table-components/ActivityHeaderCell';
const ActivityTableContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'isLoading' })(props => ({
    gap: props.isLoading ? props.theme.spacing_sizes.m : 0,
    marginBottom: props.theme.spacing_sizes.l,
}));
const TitleContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
    alignItems: 'center',
}));
const SegmentButtonsContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    gap: '25px',
    alignItems: 'center',
}));
const getActivityKeys = () => [
    {
        displayValue: `${Trans({
            ns: 'common',
            i18nKey: 'periods.month',
        })}`,
        key: 'month',
    },
    {
        displayValue: `${Trans({
            ns: 'common',
            i18nKey: 'periods.week',
        })}`,
        key: 'week',
    },
    {
        displayValue: `${Trans({
            ns: 'common',
            i18nKey: 'periods.day',
        })}`,
        key: 'day',
    },
];
const columnDef = [
    {
        header: 'data-row',
        accessorKey: 'data-row',
        enableSorting: false,
        meta: {
            flex: 2,
        },
        cell: tableRendererWrapper(ActivityHeaderCell),
    },
    {
        header: 'data-1',
        accessorKey: 'data-1',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-2',
        accessorKey: 'data-2',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-3',
        accessorKey: 'data-3',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-4',
        accessorKey: 'data-4',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-5',
        accessorKey: 'data-5',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-6',
        accessorKey: 'data-6',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-7',
        accessorKey: 'data-7',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-8',
        accessorKey: 'data-8',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-9',
        accessorKey: 'data-9',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-10',
        accessorKey: 'data-10',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-11',
        accessorKey: 'data-11',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
    {
        header: 'data-12',
        accessorKey: 'data-12',
        enableSorting: false,
        cell: tableRendererWrapper(ActivityCell),
    },
];
const ActivityTable = ({ isLoading = false, activityData = [] }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [selectedKey, setSelectedKey] = useState('day');
    const handleSelect = (value) => setSelectedKey(value);
    const activityKeys = getActivityKeys();
    const data = useMemo(() => mapTraderActivityForTable(activityData), [activityData]);
    return (_jsxs(ActivityTableContainer, { isLoading: isLoading, children: [_jsxs(TitleContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 154, height: 36, children: _jsx(TitleSecondary, { lineHeight: '24px', children: t('balance.trader_balance.activity_table.title') }) }), _jsxs(SegmentButtonsContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 140, height: 36, children: _jsx(BodySmall, { children: Trans({
                                        i18nKey: 'balance.trader_balance.activity_table.active_days',
                                        values: {
                                            count: 365
                                        }
                                    }) }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 185, height: 36, children: _jsx(SegmentButtons, { currentSegmentKey: selectedKey, onSelect: handleSelect, segments: activityKeys }) })] })] }), _jsx(Table, { columnDef: columnDef, data: data, loadingVariant: 'skeleton', components: {
                    BodyRow: ActivityBodyRow,
                }, isLoading: isLoading, defaultSkeletonRows: 5, skeletonStyles: {
                    marginBottom: theme.spacing_sizes.m,
                }, hideHeader: true })] }));
};
export default ActivityTable;
